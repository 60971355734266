<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-700px">
            <div>
                <p class="title">{{ pop.info.title }}</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view stageClass-table">
                    <tbody>
                        <tr>
                            <th width="120">상태</th>
                            <td>{{ pop.info.state }}</td>
                            <th width="120">요청자</th>
                            <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
                        </tr>
                        <tr>
                            <th width="120">대여기간</th>
                            <td width="200"> {{ pop.info.sdate }} ~ {{ pop.info.edate }}</td>
                            <th width="120">대여물품</th>
                            <td> {{ pop.info.cat2 }} > {{ pop.info.title }}</td>
                        </tr>
                        <tr>
                            <th width="120">사용목적</th>
                            <td colspan="3">{{ pop.info.use_memo }}</td>
                        </tr>
                        <tr>
                            <th width="120">사용위치</th>
                            <td colspan="3">{{ pop.info.use_loc }}</td>
                        </tr>
                        <tr>
                            <th width="120">비고</th>
                            <td colspan="3" class="preline">{{ pop.info.etc_memo }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button @click="pop.doDelete" v-if="pop.info.is_owner=='Y'&&pop.info.state=='예약 중'" class="btn-default float-left">삭제</button>
                <button @click="pop.doModify" v-if="pop.info.is_owner=='Y'&&pop.info.state=='예약 중'" class="btn-default float-right">수정</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'

export default {
    name: 'GoodsRentalViewPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const router = new useRouter();
        const toast = useToast();

        const pop = reactive({
            idx  : 0,
            info : {
                title : 'Notebook 01', state : '연체 중', kname : '김현준', ename : 'Peter', sdate : '2019-07-01', edate : '2019-07-31', cat1 : 'PC', cat2 : 'Notebook', use_memo : '특강 사용', use_loc : '당근영어 본사 F6', etc_memo : '비고'
            },

            doDelete: () => {
                Swal.fire({
                    title : '물품대여',
                    text  : '삭제하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx : pop.idx
                        };
                        axios.post('/rest/mypims/delRental', params).then((res) => {
                            if( res.data.err == 0 ){
                                pop.onClose();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doModify: () => {
                router.push({
                    name   : 'myPIMS-GoodsRentalMod-idx',
                    params : { idx:pop.idx }
                })
            },

            doSearch: () => {
                let params = {
                    idx : pop.idx
                };
                axios.get('/rest/mypims/getRentalInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            pop.idx = props.idx,
            // console.log(props.idx);
            pop.doSearch()
        })
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.btn-close { width:25px; height:25px; }
</style>