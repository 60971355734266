<template>
    <div class="con-wrap">
        <GoodsRentalViewPopup v-if="pop.isShow" @close="pop.hideDetail" :idx="pop.idx"></GoodsRentalViewPopup>
        <CarrotTitle title="물품대여 신청하기"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div class="tabDefult-col">
                    <router-link to="GoodsRentalList_tab1"><button class="tab-default on">월별</button></router-link>
                    <router-link to="GoodsRentalList_tab2"><button class="tab-default">리스트</button></router-link>
                </div>
                <div class="con-top">
                    <div class="con-top-txt">
                        - 현재 대여중인 물품 현황을 확인하고 예약할 수 있습니다.<br>
                        - 본인이 예약한 현황은 주황색으로 표시되며 이미 예약된 현황은 파란색으로 표시됩니다.<br>
                        - 예약 후 경영기획실에서 신청하신 물품을 찾아가시기 바랍니다.<br>
                        - 개인부주의로 인한 분실 및 파손시 자기부담금 : 자산구매일로부터 1년 이하 50%, 3년 이하 30%, 3년 이상 20% (구매액 기준)
                    </div>
                </div> 
                <div>

                    <div class="day-select mt-40">
                        <button class="btn btn-prev" @click="bbs.prevMonth">prev</button>
                        <button class="day btn-cal">{{ bbs.tgdate }}</button>
                        <button class="btn btn-next" @click="bbs.nextMonth">next</button>
                    </div>
                    <div class="mt-40 mb-20">
                        <div class="w-400px float-left">
                            <select name="cat1" id="cat1" class="w-45per mr-10" v-model="bbs.cat1" @change="bbs.setCat1()">
                                <option value="">중분류 전체</option>
                                <option :value="irow" v-for="(irow, i) in bbs.categories" :key="i">{{ irow.name }}</option>
                            </select>
                            <select name="cat2" id="cat2" class="w-45per" v-model="bbs.cat2" @change="bbs.doSearch()">
                                <option value="">소분류 전체</option>
                                <option :value="v" v-for="(v, k) in bbs.cat1.children" :key="k">{{ v }}</option>
                            </select>
                        </div>
                        <router-link to="GoodsRentalAdd"><button class="btn-default float-right ml-10">물품 예약하기</button></router-link>
                        <button v-if="bbs.isAuth=='Y'" @click="bbs.setReturn()" class="btn-default float-right ml-10">반납하기</button>
                        <button v-if="bbs.isAuth=='Y'" @click="bbs.setRental()" class="btn-default float-right">대여 신청하기</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-row table-cal2 calendar-rental">
                        <colgroup>
                            <col width="50">
                            <col width="130">
                            <col width="100">
                            <col :span="bbs.end"><!-- 월별 날수 -->
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>자산번호</th>
                                <th>분류</th>
                                <th v-for="(d, i) in bbs.end" :key="i">{{ d }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- td class : 나의 예약 : rvO / 예약되어있는 : rvB / ??? : rvR -->

                            <!-- 예제 시작 -->
                            <!-- <tr>
                                <td class="txt-center">
                                    <label><input type="checkbox" id="listSel1" value="listSel1"></label>
                                </td>
                                <td>Notebook 01</td>
                                <td v-for="(s, j) in (bbs.end-4)" :key="j"></td>
                                <td class=""></td>
                                <td class="rvB"></td>
                                <td class="rvR"></td>
                                <td class="rvO"></td>
                            </tr> -->
                            <!-- 예제 끝 -->

                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td class="txt-center">
                                    <label><input type="checkbox" :id="'listSel-'+i" :value="i" v-model="bbs.selected"></label>
                                </td>
                                <td>{{ irow.code }}</td>
                                <td>{{ irow.cat2 }}</td>
                                <template v-for="(d, c) in bbs.end" :key="c">
                                <td class="date" :date="bbs.cdates[c].cdate">
                                    <template v-for="(jrow, j) in irow.list" :key="j">
                                    <div :cdate="bbs.cdates[c].cdate" v-if="jrow.sdate<=bbs.cdates[c].cdate&&bbs.cdates[c].cdate<=jrow.edate" @click="pop.showDetail(jrow)" :class="jrow.state_class" :style="{ 'zIndex':j }" class="item"></div>
                                    </template>
                                </td>
                                </template>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td :colspan="2+bbs.end" class="txt-center">물품 내역이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import axios from '@/plugins/axios.js'
import GoodsRentalViewPopup from '@/components/popup/myPIMS/GoodsRentalViewPopup.vue'
import Swal from 'sweetalert2'


export default {
    layout:"myPIMS",
    components: {
        GoodsRentalViewPopup
    },
    setup() {
        const toast = useToast();
        const store = useStore();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false,
            idx   : 0,

            showDetail: (irow) => {
                // console.log(irow);
                pop.isShow = true;
                pop.idx = Number(irow.idx);
            },

            hideDetail: () => {
                bbs.doSearch();
                pop.isShow = false;
            },
        });

        const bbs = reactive({
            categories : [],
            isAuth     : 'N',             // 총무팀만 접근 권한

            cat1 : "",
            cat2 : "",

            tgdate : "",
            end    : 30,
            cdates : [],

            list : [], total : 0,

            selected : [],


            setCat1 : () =>{
                bbs.cat2 = "";
                bbs.doSearch();
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        bbs.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            setRental : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire("대여할 물품을 선택하세요.");
                    return;
                }

                let selected = [];
                for(let i=0; i<bbs.selected.length; i++){
                    let sel = bbs.selected[i];

                    let irow = bbs.list[sel];
                    for(let j=0; j<irow.list.length; j++){
                        let jrow = irow.list[j];
                        selected.push(jrow.idx);
                    }
                }

                let params = {
                    selected : selected
                };
                axios.post('/rest/mypims/setRental', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("대여 하였습니다.");
                        bbs.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            setReturn : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire("반납할 물품을 선택하세요.");
                    return;
                }

                let selected = [];
                for(let i=0; i<bbs.selected.length; i++){
                    let sel = bbs.selected[i];

                    let irow = bbs.list[sel];
                    for(let j=0; j<irow.list.length; j++){
                        let jrow = irow.list[j];
                        selected.push(jrow.idx);
                    }
                }

                let params = {
                    selected : selected
                };
                axios.post('/rest/mypims/setReturn', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("반납 하였습니다.");
                        bbs.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch: () => {
                let params = {
                    tgdate : bbs.tgdate
                };

                if( typeof bbs.cat1 == 'undefined' || bbs.cat1 == '' ){
                    params.cat1 = '';
                    params.cat2 = '';
                } else {
                    params.cat1 = bbs.cat1.name;
                    if( typeof bbs.cat2 == 'undefined' || bbs.cat2 == '' ){
                        params.cat2 = '';
                    } else {
                        params.cat2 = bbs.cat2;
                    }
                }

                bbs.selected = [];
                axios.get('/rest/mypims/getRantalMonth', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            prevMonth : () => {
                let cd = new Date(bbs.tgdate + '-01');
                // console.log(cd);
                cd.setMonth(cd.getMonth()-1);
                // console.log(cd);
                let cm = cd.getMonth()+1;
                cm = cm<10?'0'+cm:cm;
                bbs.tgdate = cd.getFullYear() + '-' + cm;
                // console.log(bbs.tgdate);

                bbs.makeCalendar();
                bbs.doSearch();
            },

            nextMonth : () => {
                let cd = new Date(bbs.tgdate + '-01');
                // console.log(cd);
                cd.setMonth(cd.getMonth()+1);
                // console.log(cd);
                let cm = cd.getMonth()+1;
                cm = cm<10?'0'+cm:cm;
                bbs.tgdate = cd.getFullYear() + '-' + cm;
                // console.log(bbs.tgdate);

                bbs.makeCalendar();
                bbs.doSearch();
            },

            makeCalendar: () => {
                let cd = new Date(bbs.tgdate + '-01');
                cd.setMonth(cd.getMonth()+1);
                cd.setDate(0);
                bbs.end = cd.getDate();
                bbs.cdates = [];
                for(let i=0; i<bbs.end; i++){
                    bbs.cdates.push({
                        cdate : bbs.tgdate + '-' + (i<9?'0'+(i+1):i+1),
                        date  : (i+1)
                    });
                }
            }
        });

        onMounted(() => {
            // Mounted

            let cd = new Date();
            let cm = cd.getMonth()+1;
            cm = cm<10?'0'+cm:cm;
            bbs.tgdate = cd.getFullYear() + '-' + cm;
            bbs.makeCalendar();

            if( store.state.isManageSupportAdmin == true ) {
                bbs.isAuth = 'Y';
            }

            bbs.getCategory();
            bbs.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
.calendar-rental {
    tbody {
        td {
            &.date {
                padding:0 !important; position:relative;
            }
            .item {
                width:100%; height:100%; cursor:pointer; position:absolute; top:0; left:0;
                &.my-rental {
                    background-color: #f79646;
                }
                &.rental {
                    background-color: #4f81bd;
                }
                &.my-delay, &.delay {
                    background-color: red;
                }
            }
        }
    }
}
</style>